import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const SignupForm = () => {

  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.ga) {
      window.ga('send', 'event', 'engagement', 'sign_up');
    }

    addToMailchimp(email)
      .then((data) => {
        alert('Thank you for subscribing!');
      })
      .catch((error) => {
        alert('Something went wrong, please try again!');
        // Errors in here are client side
        // Mailchimp always returns a 200
      });
  };

  const handleEmailFocus = (event) => {
    if (window.ga) {
      window.ga('send', 'event', 'engagement', 'sign_up_focus');
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <form onSubmit={handleSubmit} className='signupform'>
        <h2>Support Snoffeecob, keep in touch 💌</h2>
      <div className='wrapper'>
        <input
          placeholder="Email address"
          name="email"
          type="text"
          onChange={handleEmailChange}
          onFocus={handleEmailFocus}
        />
        <button type="submit">Subscribe</button>
      </div>
    </form>
  );
};

export default SignupForm;
